.ol-zoom.ol-control {
  @apply left-auto -right-[1px] top-[4.5rem] bg-transparent 2xl:top-40;
}

.ol-zoom.ol-control button {
  @apply mb-2 hidden h-14 w-14 bg-blue-600 text-white hover:text-blue-400 hover:outline-none lg:block;
}

.ol-overlay-container .tooltip-content {
  @apply bg-grey-600 text-white text-xs/3 p-2 shadow-sm rounded-sm;
}
